// src/js/main.js
import "vue-final-modal/style.css";
import "@/scss/main.scss";
import { createApp } from "vue";
import App from "@/App.vue";
import { createHead } from "@vueuse/head";
import { createVfm } from "vue-final-modal";
import { i18n } from "@/js/i18nController";

import { initializeGtag } from "./gtag";

const app = createApp(App);
const head = createHead();
const vfm = createVfm();

// Функция определения типа устройства
function getTrackingId() {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return isMobile ? "G-ER6GQH9RKC" : "G-ER6GQH9RKC"; // Замените на ваши реальные ID
}

// Динамическая загрузка gtag.js
function loadGtagScript(trackingId) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.onload = () => resolve(trackingId);
    script.onerror = () => reject(new Error("Не удалось загрузить gtag.js"));
    document.head.appendChild(script);
  });
}

// Основной процесс загрузки и инициализации
(async function setupGtag() {
  try {
    const trackingId = getTrackingId();
    //   console.log(`Используем Tracking ID: ${trackingId}`);
    await loadGtagScript(trackingId); // Ждём загрузки gtag.js
    initializeGtag(trackingId); // Инициализируем gtag с нужным ID
  } catch (error) {
    //   console.error('Ошибка инициализации gtag:', error);
  }
})();

app.use(head);
app.use(vfm);
app.use(i18n);
app.mount("#app");
